require('jquery');
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/dist/css/swiper.min.css';
import 'animate.css';

import './vendor/SlickNav/dist/jquery.slicknav.min';
import './vendor/SlickNav/dist/slicknav.min.css';

require('select2');
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

$(function() {
    $('#l-menu__mobile').slicknav({
        label: '',
        duration: 1000,
        // easingOpen: "easeOutBounce", //available with jQuery UI
        prependTo:'#c-menu__mobile'
    });
});

import './_fontawesome.js';
import sliders from './_sliders.js';
import utility from './_utility.js';
import animation from './_animation.js';
import maps from './_maps';
import tooltips from './_tooltips';


$().ready(() => {
    sliders();
    utility();
    maps();
    animation();
    tooltips();
});

import '../sass/app.scss';