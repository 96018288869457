import Swiper from 'swiper';

export default function sliders() {
    var homepageSwiper = new Swiper('.swiper-container', {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        autoplay: {
            delay: 7000,
        },
    });
}
