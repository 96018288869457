export default function utility() {
    $('.u-jump-to').click(function(e) {
        e.preventDefault();
        let button = $(this);
        var offset = 50;
        let buttonOffset = button.data('scroll-offset');
        if (buttonOffset != null) {
            offset = buttonOffset;
        }
        $('html, body').animate({
            scrollTop: $(button.attr('href')).offset().top - offset
        }, 800);
    });
    $('#c-offer-country-select').on('change', function() {
        document.cookie = 'international_offer_country='+encodeURIComponent($(this).val())+';path=/';
        // location.reload();
        window.location.replace(location.pathname+'?sdc='+$(this).val());
    });
    $('.c-distribution-country-select').select2({
        theme: 'bootstrap4'
    });
};
