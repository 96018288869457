export default function animation() {

    $.fn.visible = function(partial) {

        var $t            = $(this),
            $w            = $(window),
            viewTop       = $w.scrollTop(),
            viewBottom    = viewTop + $w.height(),
            _top          = $t.offset().top,
            _bottom       = _top + $t.height(),
            compareTop    = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

    };


    var win = $(window);

    var allMods = jQuery(".slide-in");

    allMods.each(function(i, el) {
        var el = $(el);
        if (el.visible(true)) {
            el.addClass("already-visible");
        }
    });

    var animated = $('.use-animated');
    // console.log(animated);

    function startAnimated(el) {
        var el = $(el);
        if (!el.hasClass('animated') && el.visible(true)) {
            el.addClass('animated visible ' + el.data('animatedClass'));
        }
    }

    animated.each(function(i, el) {
        startAnimated(el);
    });

    win.scroll(function(event) {
        allMods.each(function(i, el) {
            var el = $(el);
            if (el.visible(true)) {
                if (el.hasClass("left") || el.hasClass("left-2")) {
                    el.addClass("come-in-left");
                }
                else if (el.hasClass("right") || el.hasClass("right-2")) {
                    el.addClass("come-in-right");
                }
                else if (el.hasClass("bottom") || el.hasClass("opacity")) {
                    el.addClass("come-in-bottom");
                }
            }
        });

        animated.each(function (i, el) {
            startAnimated(el);
        });
    });

    // Wheel animation
    function animateWheel() {
        var wheel = $(".wheel");
        setTimeout(function() {
            wheel.find(".a-1").addClass("animated fadeInUp visible");
        }, 0);
        setTimeout(function() {
            wheel.find(".a-2").addClass("animated zoomIn visible");
        }, 200);
        setTimeout(function() {
            wheel.find(".a-3").addClass("animated zoomIn visible");
        }, 400);
        setTimeout(function() {
            wheel.find(".a-4").addClass("animated bounceIn visible");
        }, 1000);
        setTimeout(function() {
            wheel.find(".a-5").addClass("animated bounceIn visible");
        }, 1100);
        setTimeout(function() {
            wheel.find(".a-6").addClass("animated bounceIn visible");
        }, 1200);
        setTimeout(function() {
            wheel.find(".a-7").addClass("animated bounceIn visible");
        }, 1300);
        setTimeout(function() {
            wheel.find(".a-8").addClass("animated bounceIn visible");
        }, 1400);
        setTimeout(function() {
            wheel.find(".a-9").addClass("animated bounceIn visible");
        }, 1500);
        setTimeout(function() {
            $(".line-grow").addClass("grow");
        }, 1600);
        setTimeout(function() {
            wheel.find(".a-10").addClass("animated fadeInLeft visible");
            wheel.find(".a-11").addClass("animated fadeInRight visible");
        }, 2000);
        // $(".wheel-link").parent().click(function(){
        //     $(this).removeClass('animated');
        //     $(this).removeClass('fadeInLeft');
        //     $(this).removeClass('fadeInRight');
        //     $(this).removeClass('rubberBand');
        //     $(this).addClass("animated rubberBand");
        // });
    }
    win.scroll(function(event) {
        if ($(".wheel").length && $(".wheel").visible(false) && window.wheelTriggered !== true) {
            window.wheelTriggered = true;
            animateWheel();
        }
    });

};



// var win = $(window);
//
// var allMods = $(".slide-in");
// console.log(allMods);
//
// allMods.each(function(i, el) {
//     var el = $(el);
//     if (el.visible(true)) {
//         el.addClass("already-visible");
//     }
// });
//
// win.scroll(function(event) {
//
//     allMods.each(function(i, el) {
//         var el = $(el);
//         if (el.visible(true)) {
//             if (el.hasClass("left")) {
//                 el.addClass("come-in-left");
//             }
//             else if (el.hasClass("right")) {
//                 el.addClass("come-in-right");
//             }
//             else if (el.hasClass("bottom")) {
//                 el.addClass("come-in-bottom");
//             }
//         }
//     });
//
// });